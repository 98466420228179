import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from '../components';

import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [NgbNavModule, RouterModule],
    declarations: [HeaderComponent],
    exports: [HeaderComponent]
})
export class SharedModule { }